import { debounce } from 'lodash'
import fieldMixins from '@/mixins/fields'
import advancedFieldMgmt from '@/services/advancedFieldMgmt'
import CustomFieldTypes from '@/constants/customFieldTypes'
import { advancedFieldValue } from '@/helpers/customFields'
import dayjs from 'dayjs'
import user from '@/store/user'

const listMixinFactory = ({
  isHistory = false,
  listFormat = false,
  autoRouteName = false,
  isCustomTableColumns = false,
  isCustomService = false,
  isCustomizable = false,
  routeName = '',
  disableAutoFetchlist = false,
  sort = {
    name: 'firstName',
    email: 'email',
    mobileNumber: 'mobileNumber',
    status: 'status',
    jobTitle: 'jobTitle',
  },
  tableColumns = [
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'mobileNumber', label: 'Mobile', sortable: true },
    { key: 'jobTitle', label: 'Role', sortable: true },
    { key: 'groups', class: 'text-center disabled' },
    { key: 'status', sortable: true },
    { key: 'actions', class: 'text-center disabled' },
  ],
  service = callback => callback([]),
}) => ({
  mixins: [fieldMixins],
  data() {
    return {
      resourceId: null,
      showForm: false,
      customizeLoading: false,
      items: [],
      loading: true,
      perPage: 10,
      total: 100,
      filters: null,
      userId: this.$route.query.userId || '',
      groupId: this.$route.query.groupId || '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: this.$route.query.page || 1,
      search: this.$route.query.search || '',
      statusId: this.$route.query.statusId || '',
      sortBy: this.$route.query.sortBy || '',
      sortDesc: this.$route.query.sortDesc === 'true',
      companyContact: this.$route.query.companyContact || false,
      statusFilter: null,
      tableColumns,
      filterOptions: [],
      routeQuery: {},
      startDate: null,
      endDate: null,
      year: null,
      service,
      searchable: [],
      isDeleted: false,
      isEditMode: false,
      filterByCurrentUser: false,
      disableAutoFetchlist,
    }
  },
  created() {
    if (isCustomService) {
      this.service = this.customService
    }
    if (isCustomTableColumns && !isCustomizable) {
      this.tableColumns = this.customTableColumns
    }
    if (isCustomizable) this.getColumnFields()
  },
  mounted() {
    this.refTable = this.$refs.refTable
    if (!this.disableAutoFetchlist) {
      this.fetchList()
    }
  },
  computed: {
    routeName() {
      return this.autoRouteName ? this.$route.name : routeName
    },
    dataMeta() {
      let localItemsCount = 0

      if (this.refTable) {
        localItemsCount = this.refTable.localItems.length
      }

      if (listFormat) {
        localItemsCount = this.items.length
      }

      return {
        from: this.perPage * (this.currentPage - 1) + 1,
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.total,
      }
    },
  },
  watch: {
    '$route.params': function (params) {
      if (this.routeWatcher) {
        return this.routeWatcher(params)
      }

      if ((params.tab === 'history' && isHistory) || !params.id) {
        this.fetchList()
      }
    },
  },
  methods: {
    findValue({ field, item }) {
      let standardValue = item[this.camelize(field.key)]
      const fieldType = field.type
      const value = advancedFieldValue(standardValue)

      if (fieldType === CustomFieldTypes.Date) standardValue = value ? dayjs(value).format('MMMM DD, YYYY') : ''
      if (fieldType === CustomFieldTypes.Date && field.key === 'received_date') standardValue = value ? dayjs(value).format('MMMM DD, YYYY HH:mm') : ''
      if (field.customField) {
        if (typeof value === 'object') {
          return value ?? value?.map(val => val.name).join(',')
        }

        if (fieldType === CustomFieldTypes.Percent) {
          return `${this.$n(value * 100)}%` ?? value
        }

        if (fieldType === CustomFieldTypes.Number) {
          return value ?? this.$n(value)
        }

        if (fieldType === CustomFieldTypes.Date) {
          return standardValue ?? value
        }

        return value ?? ''
      }
      return standardValue
    },

    isTitle({ field }) {
      return field.partOfName
    },
    handleSortChange({ sortBy, sortDesc, currentPage }) {
      this.routeQuery.sortBy = sortBy
      this.routeQuery.sortDesc = sortDesc

      this.updateRouteQuery()
    },

    handleStatusFilter(id) {
      this.routeQuery.statusId = id
      this.updateRouteQuery()
    },
    handleGroupFilter(group) {
      this.routeQuery.groupId = group?.groupId
      this.updateRouteQuery()
    },
    handleUserFilter(userFilter) {
      this.routeQuery.userId = userFilter?.userId
      this.updateRouteQuery()
    },
    handleContactPersonFilter(contactPerson) {
      this.routeQuery.companyContact = contactPerson
      this.updateRouteQuery()
    },

    handleFilterByCurrentUser(val) {
      this.routeQuery.filterByCurrentUser = val
      this.updateRouteQuery()
    },

    applyFIlter(query) {
      this.filters = query
      this.routeQuery.filters = query
      // Object.entries(query).forEach(([key, value]) => {
      //   if (value) this.routeQuery[key] = value
      //   else delete this.routeQuery[key]
      // })

      this.updateRouteQuery()
    },
    async applyYearFilter(year) {
      if (year == null) {
        this.year = null
        delete this.routeQuery.year
      } else {
        this.year = year
        this.routeQuery.year = year
        if (this.routeQuery.startDate != null) {
          this.startDate = null
          delete this.routeQuery.startDate
        }
        if (this.routeQuery.endDate != null) {
          this.endDate = null
          delete this.routeQuery.endDate
        }
      }
      this.updateRouteQuery()
    },
    async applyDateFilter([startDate, endDate]) {
      this.applyYearFilter(null)
      this.startDate = startDate
      this.endDate = endDate
      this.routeQuery.startDate = startDate
      this.routeQuery.endDate = endDate
      this.updateRouteQuery()
    },
    handleSearch(value) {
      this.doSearch(value)
    },

    async getColumnFields() {
      this.customizeLoading = true

      const { response } = await this.$async(advancedFieldMgmt.getFieldsByEntity(this.getSchemaTableName))

      if (!response) return
      // GET AFM filterable fields
      this.filterOptions = response.data
        ?.filter(item => item.filterable)
        .map(field => ({
          ...field,
          field: {
            fieldKey: field.key,
            paramValue: field.defaultValue,
            type: field.type,
          },
        }))

      let fields = response.data.filter(data => !data.hidden)

      this.searchable = response.data.filter(data => data.searchable)

      if (this.routeName === 'inquiries' || this.routeName === 'todo' || this.routeName === 'documents') {
        fields = fields.map(field => ({
          ...field,
          sortable: true,
        }))
      } else {
        fields = fields.map(field => ({
          ...field,
          sortable: field.key.includes('name'),
        }))
      }

      if (this.routeName === 'documents') {
        fields.push({ key: 'connectedTo', label: 'Connected To', class: 'disabled' }, { key: 'createdTimestamp', label: 'System Generated', sortable: true })
      }

      if (this.routeName === 'companies' || this.routeName === 'contacts') {
        fields.unshift({
          key: 'statusName',
          label: 'Status',
          sortable: true,
        })
      }

      if (this.routeName === 'inquiries' || this.routeName === 'todo') {
        fields.unshift({ key: 'refId', label: 'Reference Number', sortable: true })
        fields.push(
          { key: 'companyName', label: 'Company Name', sortable: true },
          { key: 'contactFirstName', label: 'Contact', sortable: true },
          { key: 'statusName', label: 'Status', sortable: true },
          { key: 'priorityName', label: 'Priority', sortable: true },
          { key: 'categoryName', label: 'Category', sortable: true },
          { key: 'sourceName', label: 'Source', sortable: true },
          { key: 'assignedUserFirstName', label: 'Assigned To', sortable: true },
          { key: 'actions', class: 'text-center disabled', stickyColumn: true },
        )
      }

      this.tableColumns = this.customEntity?.columns
        ? this.customEntity.columns
        : (this.tableColumns = [...fields, { key: isCustomTableColumns ? '__actions' : 'actions', class: 'text-center disabled' }])

      this.customizeLoading = false
    },
    updateRouteQuery() {
      const routeData = { name: this.routeName, query: this.routeQuery }

      if (this.$route?.params) {
        routeData.params = this.$route?.params
      }

      this.$router.push(routeData).catch(() => {})
    },
    handleCurrentPage(value) {
      const { page } = this.$route.query
      if (!value || value === parseInt(page)) return // prevent calling again when "going back" to previous page
      this.routeQuery.page = value
      this.updateRouteQuery()
    },
    doSearch: debounce(function (value) {
      this.routeQuery.page = 1
      this.routeQuery.search = value

      this.updateRouteQuery()
    }, 500),
    loadCustomFieldData(items) {
      return items.reduce((acc, item) => {
        if (!item.customFieldData) {
          const getRowName = (itemConnections, connType) => {
            if (!itemConnections) return null

            const companyConnection = itemConnections.find(connection => connection.tableKey === connType)
            if (!companyConnection || companyConnection.rows.length === 0) return null
            return companyConnection.rows[0]?.rowName
          }

          if (!!item.values && Object.hasOwn(item.values, 'selskapstilhørighet')) {
            item.values.selskapstilhørighet = getRowName(item.connections, 'company')
          }

          if (!!item.values && Object.hasOwn(item.values, 'kontakttilhørighet')) {
            item.values.kontaktertilhørighet = getRowName(item.connections, 'contact')
          }

          acc.push(item)
          return acc
        }

        let processedFields = []

        if (this.customFields) {
          processedFields = item.customFieldData
            .map(field => {
              const metadata = this.customFields.find(meta => meta.id === field.metadataId)
              return {
                label: metadata.label,
                value: field.value,
                fieldType: metadata.fieldType,
              }
            })
            .filter(field => !!field)
            .sort((a, b) => b.favorite - a.favorite)
        }

        item.canShowMore = () => processedFields.length > 1
        item.getFirstItem = () => (processedFields.length > 0 ? processedFields[0] : null)
        item.showMoreFields = () => {
          const fields = [...processedFields]
          if (fields.length > 1) {
            fields.shift()
            return fields
          }
          return []
        }
        acc.push(item)
        return acc
      }, [])
    },

    async fetchList(ctx) {
      this.loading = true

      const { page, search, sortBy, sortDesc, statusId, filters, startDate, endDate, userId, groupId, companyContact, year, filterByCurrentUser } = this.$route.query

      if (page === null) return

      const filterParse = filters
      this.currentPage = page || 1
      this.userId = userId
      this.groupId = groupId
      this.search = search
      this.startDate = startDate
      this.endDate = endDate
      this.filters = filterParse
      this.sortBy = sortBy
      this.statusId = statusId
      this.companyContact = companyContact
      if (this.sortBy) {
        this.sortDesc = sortDesc === 'true' || sortDesc === true
      }
      this.routeQuery.filters = JSON.stringify(filterParse)
      this.routeQuery.userId = this.userId
      this.routeQuery.groupId = this.groupId
      this.routeQuery.statusId = this.statusId
      this.routeQuery.page = this.currentPage
      this.routeQuery.search = this.search
      this.routeQuery.sortBy = this.sortBy
      this.routeQuery.companyContact = this.companyContact
      this.routeQuery.filterByCurrentUser = this.filterByCurrentUser
      const query = {
        page: page ? page - 1 : 0,
        size: this.perPage,
      }

      if (userId) {
        // this.$refs.userGroupFilter.getUserData(userId)
        query.userId = `=${userId}`
      } else {
        delete query.userId
      }

      if (groupId) {
        // this.$refs.userGroupFilter.getGroupData(groupId)
        query.groupId = `=${groupId}`
      } else {
        delete query.groupId
      }

      if (this.sortBy) {
        query.sortBy = `${this.sortDesc ? 'desc' : 'asc'}.${sort[this.sortBy] || this.sortBy}`
      }

      if (search) {
        this.handleQueryPayload(search, query)
        query.q = search
      } else {
        delete query.q
      }

      if (statusId) {
        query.statusId = `=${statusId}`
      } else {
        delete query.statusId
      }
      if (startDate && endDate) {
        if (this.routeName === 'commissions') {
          query.startDate = startDate
          query.endDate = endDate
        } else {
          query.createdTimestamp_start = startDate
          query.createdTimestamp_end = endDate
        }

        // Apply value to date range reusable filter
        this.$refs.dateRangeFilter.range = [startDate, endDate]
      } else {
        delete query.createdTimestamp_start
        delete query.createdTimestamp_end
      }

      if (companyContact) {
        query.company_contact = `=${companyContact}`
      } else delete query.company_contact

      if (this.filters) {
        Object.entries(this.filters).forEach(([key, value]) => {
          if (value) query[key] = value
          else delete query[key]
        })
      }

      if (this.$route?.params?.tab === 'commissions') {
         if (this.$route?.name === 'company-view') {
            query.companyUuid = this.$route.params.id
         } else if (this.$route?.name === 'contact-view') {
            query.contactUuid = this.$route.params.id
         } else {
            query?.companyUuid && delete query.companyUuid
            query?.contactUuid && delete query.contactUuid
         }
      }
      if (filterByCurrentUser) {
         query.filterByCurrentUser = filterByCurrentUser
      }

      this.getRequest(query)
        .then(res => {
          if (this.afterFetchCallback) {
            this.afterFetchCallback(res)
          } else {
            this.total = res.data.filteredItemsCount || res.data.filteredItemCount
            this.items = this.loadCustomFieldData(res.data.pageItems)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getRequest(query) {
      if (this.getAllMethod) {
        return this.getAllMethod(query)
      }

      return isHistory ? this.service.getHistory(this.$route.params.id, query) : this.getAll(query)
    },

    getAll(query) {
      if (isCustomTableColumns || !isCustomizable) return this.service.getAll(query)

      if (this.routeName === 'sales-contacts') {
        return this.service.getAllSellersByJob(this.$route.params.id, this.$route.params.tableName, query)
      }

      if (this.routeName === 'commission-models') {
        return this.service.getModels(query)
      }

      if (this.routeName === 'commissions') {
        if (this.year != null) {
          query.year = this.year
        }
        return this.service.getAllCommissions(query)
      }
      return advancedFieldMgmt.getDataFeed(query, this.getSchemaTableName.schemaKey, this.getSchemaTableName.tableKey)
    },
    deleteResource(id, version, config = {}) {
      const versionKey = config.versionKey || 'version'

      this.service
        .destroy(id, {
          [versionKey]: version,
        })
        .then(() => {
          this.fetchList()
          this.isDeleted = !this.isDeleted
        })
    },
    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },
    edit(id) {
      this.resourceId = id
      this.isEditMode = true
      this.showForm = true
    },
    handleQueryPayload(value, query) {
      const isNumber = !isNaN(value)

      this.searchable.forEach(item => {
        if (this.routeName === 'contacts' && !isNumber) query.fullName = `ilike'%${value}%'`
        if (isNumber && (item.type === 'PERCENT' || item.type === 'NUMBER')) {
          const percent = value.toString() / 100
          query[item.key] = item.type === 'PERCENT' ? `=${percent.toFixed(4)}` : `=${value}`
        } else if (item.type !== 'BOOL' && item.type !== 'PERCENT' && item.type !== 'DATE' && item.type !== 'NUMBER') {
          query[item.key] = `ilike'%${value}%'`
        }
      })
    },
  }, // methods
})

export default listMixinFactory
